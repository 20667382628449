<template>
	<section class="add-banner__modal-container">
		<div class="add-banner__upload-wrapper">
			<div class="add-logo__top-container">
				<font-awesome-icon @click="closeModal" class="add-logo__close-icon" icon="times"></font-awesome-icon>
				<p class="add-logo__title">
					Formato PNG o JPEG - Dimensiones 240x357 px - Tamaño máximo 2 MB
				</p>
			</div>

			<div class="add-logo__middle-container">
				<UploadImg @sendImg="handleBannerSelect" v-if="img_preview_src == ''" :viewport="viewport" />
				<div class="img-preview" v-if="img_preview_src !== ''">
					<div class="delete-preview" @click="deletePreview()">
						<button>
							<font-awesome-icon icon="times"></font-awesome-icon>
						</button>
					</div>
					<div class="img-preview-container">
						<img :src="img_preview_src" alt="" />
					</div>
				</div>

				<div class="d-flex justify-content-center">
					<p class="add-banner__legend-text text-end mb-0">Vista previa</p>
				</div>
			</div>

			<div class="add-logo__buttons-container">
				<button @click="closeModal" class="add-logo__button-cancel">Cancelar</button>
				<button @click="loadBanner" class="add-logo__button-add">Guardar</button>
			</div>
		</div>
		<Spinner v-show="show_spinner == true" />
	</section>
</template>
    
<script>
import { mapState, mapActions } from "vuex";
import UploadImg from "../UploadImg.vue";
import Spinner from "../Spinner.vue";

export default {
	components: { UploadImg, Spinner },
	data() {
		return {
			id_empresa: this.$ls.get("user").empresa[0].id_empresa,
			id_usuario: this.$ls.get("user").id_usuario,
			show_spinner: false,
			img_preview_src: "",
			viewport: { width: 240, height: 357 },
			newBanner: {}
		};
	},

	computed: {
		...mapState("tvCorporativaModule", ["channelLayout"]),
	},

	methods: {
		...mapActions("tvCorporativaModule", ["uploadFileRepositorio"]),

		async loadBanner() {
			const data = {
				id_empresa: this.id_empresa,
				id_usuario: this.id_usuario,
				tipo_archivo: "2",
				file: this.newBanner
			};
			this.show_spinner = true;
			if (this.newBanner.type.includes("png") || this.newBanner.type.includes("jpeg")) {
				const uploadedFileObj = await this.uploadFileRepositorio(data);
				this.show_spinner = false;
				if (!uploadedFileObj) {
					this.toastBannerFallaCarga();
				} else {
					this.toastBannerCargaExitosa();
					this.$emit("bannerListChanged");
				}
				this.$emit("closeModal");
			} else {
				this.toastBannerFallaFormato();
			}
		},

		handleBannerSelect(img) {
			this.newBanner = {
				width: img.width,
				height: img.height,
				src: img.src,
				type: "image/" + img.format,
				name: img.name,
				duration: 0,
			};
		},

		closeModal() {
			this.$emit("closeModal");
		},

		deletePreview() {
			this.newBanner = {
				width: "",
				height: "",
				src: "",
				type: "",
				name: "",
			};
		},

		toastBannerFallaCarga() {
			this.$toast.open({
				message: "Falla al momento de cargar banner.",
				type: "error",
				duration: 6000,
				position: "top-right",
			});
		},
		toastBannerCargaExitosa() {
			this.$toast.open({
				message: "El banner ha sido cargado exitosamente.",
				type: "success",
				duration: 6000,
				position: "top-right",
			});
		},
		toastBannerFallaFormato() {
			this.$toast.open({
				message: "El archivo seleccionado no es una imagen. Por favor selecciona un archivo con formato de imagen (.png, jpeg).",
				type: "warning",
				duration: 6000,
				position: "top-right",
			});
		}
	},
};
</script>

<style lang="scss" scoped>
*::v-deep .upload-img-container .preview-img {
	position: relative;
	width: 100%;
	height: 100%;
}

*::v-deep .upload-img-container {
	border: 1px dashed #0072f7;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 260px;
	height: 350px;
	cursor: auto;
}

*::v-deep .upload-img-modal-container-image {
	width: 100%;
	min-height: 350px;
	position: absolute;
	top: 40px;
	left: 0;
	right: 0;
	display: flex;
	justify-content: center;
}
</style>